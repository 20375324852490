<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="평가 이력"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        :usePaging="false"
        :isFullScreen="false"
      >
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-assess-history',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmVendorEvaluationId: '',
        vendorCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'vendorEvaluationKindName',
            field: 'vendorEvaluationKindName',
            label: '평가구분',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            label: '평가점수',
            child: [
              {
                name: 'totalScore',
                field: 'totalScore',
                style: 'width:100px',
                label: '총점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'score',
                field: 'score',
                style: 'width:100px',
                label: '평점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'evaluationGradeName',
                field: 'evaluationGradeName',
                style: 'width:100px',
                label: '등급',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            label: '평가기간',
            style: 'width:250px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '평가결과 종합',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cim.vendor.evaluation.result.history.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {vendorCd: this.param.vendorCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>